import * as React from "react"
import { graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout/Layout';
import styled from "@emotion/styled"

import FlavourList from "../content/flavours.json";

import ogImage from "../../static/metadata/flavours/flavours.og.jpg";
import twitImage from "../../static/metadata/flavours/flavours.twitter.jpg";

const blissfulColour = "#ee9fa0;"

const Container = styled.div`
  margin: 3rem auto;
  max-width: 1280px;
  padding: 0 20px;
`

const FlavourDescription = styled.p`
  margin: 10px 0;
`

const Asterisk = styled.span`
  color: ${blissfulColour};
`

const FlavourTitle = styled.h3`
  position: relative;
`

// const PopularFlavourFlag = styled.span`
//   background: ${blissfulColour};
//   color: white;
//   font-size: 0.65rem;
//   letter-spacing: 1px;
//   margin: 0 5px;
//   padding: 5px;
//   border-radius: 5px;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);

//   ::before {
//     content: 'POPULAR';
//   }
// `

const PopularFlavourFlag = styled.span`
  font-size: 0.65rem;
  letter-spacing: 1px;
  color: white;
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
  background: 
    linear-gradient(-45deg,#0000 33%,rgba(255,255,255,0.7) 50%,#0000 66%)
    ${blissfulColour};
  background-size:400% 100%;
  animation: shine 2s infinite;

  white-space: nowrap;
  display: inline-block;

  position: relative;
  top: -5px;

  ::before  {
    content: "POPULAR";
  }
  

  @keyframes shine {
  0% {
    background-position: right;  
  }
}
`

const Seasonal = styled.span`
  font-size: 0.65rem;
  letter-spacing: 1px;
  color: white;
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
  background: 
    linear-gradient(90deg,#0000 33%,rgba(255,255,255,0.7) 50%,#0000 66%)
    ${blissfulColour};
  background-size:400% 100%;
  animation: shine 1.5s infinite;

  white-space: nowrap;
  display: inline-block;

  position: relative;
  top: -5px;

  ::before  {
    content: "SEASONAL";
  }
  

  @keyframes shine {
  0% {
    background-position: right;  
  }
}
`

const FlavourCategory = styled.h2`
  color: ${blissfulColour};
  text-align: center;
`

const FlavourCategorySubtitle = styled.p`
  text-align: center;
  margin-top: -30px;
  font-style: italic;
  font-weight: 100;
  font-size: 0.8rem;
  color: darkgray;
  @media screen and (min-width: 960px) {
    font-size: 1rem;
  }
`

const FlavourContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Flavour = styled.div`
  width: calc(33% - 15px);
  margin-right: 15px;
  @media screen and (max-width: 960px) {
    margin: 5px 0px;
    flex-basis: 100%;
  }
`

const HeroImageContainer = styled.div`
  max-width: 100%;
  div, img {
    max-width: 100%;
    width: 100%;
    max-height: 50vh;
    object-fit: cover !important;
    object-position: 0% 33%;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }  
  @media screen and (min-width: 960px) {
    div, img {
      height: 100%;
      max-height: 550px;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`

const Flavours = ({ data }) => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  return(
    <Layout
      title="Delicious Cake & Cupcake Flavours"
      description="See a list of all our delicious flavours including fan favourites! Which flavour will you choose for your special occasion? "
      pathname={pathname}
      ogImage={ogImage}
      twitImage={twitImage}
    >   
      <HeroImageContainer>
        <StaticImage
          src="../images/flavours-hero-desktop.jpeg"
          alt="Three individual slices of different flavoured cake in a horizontal line"
          placeholder="blurred"
          layout="constrained"
          quality={85}
          className="desktop"
        />
        <StaticImage
          src="../images/flavours-hero-mobile.jpeg"
          alt="Four individual slices of different flavoured cake in a vertical line"
          placeholder="blurred"
          layout="constrained"
          quality={80}
          className="mobile"
        />
      </HeroImageContainer>
      <Container>
        {/* <TitleHeader> Cake Flavours </TitleHeader> */}
        <FlavourDescription> All flavours are available in a creamy, less sweet buttercream and a sweet buttercream. You must specify which you prefer on the contract or the cake artist will choose. </FlavourDescription>
				<FlavourDescription> Cheesecake layers can be added to any flavour. </FlavourDescription>
        <FlavourDescription> (Please note that deluxe and premium flavours cost more depending on the selection.) </FlavourDescription>
				<FlavourDescription> Please be advised that baked goods are made in an establishment which may contain the following: fish, wheat, dairy, eggs, and nuts. </FlavourDescription>

        { FlavourList.flavour_categories.map((category) => {
          return <div key={category.title}> <FlavourCategory> {category.title} </FlavourCategory>
            <FlavourCategorySubtitle> {category.subtitle} </FlavourCategorySubtitle>
            <FlavourContainer>
              { category.flavours.map((flavour) => {
                return <Flavour key={flavour.flavour}>
                <FlavourTitle> 
                  {flavour.flavour}
                  {flavour.popular_flavour ? <PopularFlavourFlag title="Popular Flavour"/> : ''} 
                  {/* {flavour.blissfuls_choice ? <BlissfulsChoice></BlissfulsChoice> : ''} */}
                  {flavour.seasonal ? <Seasonal></Seasonal> : ''}
                </FlavourTitle>
                <p> {flavour.description} </p>
              </Flavour>
              }) }
            </FlavourContainer>
          </div>
        }) }

        </Container>
    
    </Layout>
  )
}

export default Flavours

